// Import React
import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';

// Import others types
import ColumnHeader from '../types/ColumnHeader';

// Import shared constants
import IN_CASE_OF_EMERGENCY_SUFFIX from '../../../../shared/constants/IN_CASE_OF_EMERGENCY_SUFFIX';

// Create checkmark examples
const checkedExample = (
  <FontAwesomeIcon icon={faCheckSquare} />
);
const uncheckedExample = (
  <FontAwesomeIcon icon={faSquare} />
);

/**
 * List of column headers
 * @author Gabe Abrams
 */
const COLUMN_HEADERS: ColumnHeader[] = [
  /* ------------------- Required Input Columns ------------------- */
  {
    prop: 'batchCreate',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: true,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'batch create class?',
    ],
    description: 'Rows with a checked checkbox here will be read by Gather.',
    examples: [checkedExample, uncheckedExample],
  },
  {
    prop: 'crn',
    type: 'number',
    allowSpaces: undefined, // Not relevant to numbers
    required: true,
    defaultValue: undefined, // No default value! Cells cannot be empty.
    isWriteColumn: false,
    names: [
      'crn', // Primary
      'course crn',
    ],
    description: 'The course CRN.',
    examples: [13856, 39482],
  },
  {
    prop: 'courseCode',
    type: 'string',
    allowSpaces: true,
    required: true,
    defaultValue: undefined, // No default value! Cells cannot be empty.
    isWriteColumn: false,
    names: [
      'course', // Primary
      'course code',
    ],
    description: 'A short text description of the course.',
    examples: ['CS 900 Algorithms', 'ENGL 100 Creative Writing'],
  },
  {
    prop: 'hostEmail',
    type: 'string',
    allowSpaces: false,
    required: true,
    defaultValue: undefined, // No default value! Cells cannot be empty.
    isWriteColumn: false,
    names: [
      'class zoom host email', // Primary
      'class zoom email',
      'class zoom host',
      'class host email',
      'class host',
    ],
    description: 'The Zoom account email for the user who will own the event.',
    examples: ['teacher@harvard.edu', 'ta@fas.harvard.edu'],
  },

  /* ------------------ Optional Columns ------------------ */

  {
    prop: 'isLockAutoRecordSettingOn',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'lock auto record setting for class?', // Primary
    ],
    description: 'If checked, auto record setting will be editable by non-admins.',
    examples: [checkedExample, uncheckedExample],
  },
  {
    prop: 'isDCEBanOn',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'ban dce students from class?', // Primary
    ],
    description: 'If checked, DCE students will not be allowed to join the event.',
    examples: [uncheckedExample, uncheckedExample],
  },
  {
    prop: 'isFASBanOn',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'ban fas students from class?', // Primary
    ],
    description: 'If checked, FAS students will not be allowed to join the event.',
    examples: [uncheckedExample, uncheckedExample],
  },
  {
    prop: 'isAutoRecordOn',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'auto record class?', // Primary
    ],
    description: 'If checked, auto record will be turned on.',
    examples: [uncheckedExample, uncheckedExample],
  },
  {
    prop: 'isWaitingRoomOn',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'class waiting room on?', // Primary
    ],
    description: 'If checked, waiting room will be turned on (webinars cannot have waiting rooms).',
    examples: [uncheckedExample, uncheckedExample],
  },
  {
    prop: 'isEmergencyEvent',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'class is emergency event?', // Primary
    ],
    description: `If checked, event title will get an "${IN_CASE_OF_EMERGENCY_SUFFIX}" suffix.`,
    examples: [checkedExample, uncheckedExample],
  },
  {
    prop: 'ensureLoungeExists',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'ensure lounge exists?', // Primary
    ],
    description: 'If checked and no study lounge exists, create a study lounge under same Zoom host.',
    examples: [checkedExample, uncheckedExample],
  },
  {
    prop: 'hostVideoDisabled',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'force host video off?', // Primary
    ],
    description: 'If checked, host/panelist video will be disabled. Otherwise, user setting will be used.',
    examples: [uncheckedExample, checkedExample],
  },
  {
    prop: 'customEventName',
    type: 'string',
    allowSpaces: true,
    required: false,
    defaultValue: 'Class',
    isWriteColumn: false,
    names: [
      'custom event name', // Primary
    ],
    description: 'Custom text for the event name, defaults to "Class".',
    examples: ['Class: HELIX Live Stream', 'Class: HELIX Classroom'],
  },
  {
    prop: 'isWebinar',
    type: 'boolean',
    allowSpaces: undefined, // Not relevant to booleans
    required: false,
    defaultValue: false,
    isWriteColumn: false,
    names: [
      'class is a webinar?', // Primary
    ],
    description: 'If checked, the event will be created as a webinar. Otherwise, created as a meeting.',
    examples: [uncheckedExample, checkedExample],
  },

  /* ----------------------- Output Columns ----------------------- */

  {
    prop: 'zoomPassword',
    type: 'string',
    allowSpaces: undefined, // Not relevant to write columns
    required: true,
    defaultValue: undefined, // Not relevant to write columns
    isWriteColumn: true,
    names: [
      'class password', // Primary
      'class passcode',
    ],
    description: 'The password for the event.',
    examples: ['(Gather will fill)', '(Gather will fill)'],
  },
  {
    prop: 'openZoomLink',
    type: 'string',
    allowSpaces: undefined, // Not relevant to write columns
    required: true,
    defaultValue: undefined, // Not relevant to write columns
    isWriteColumn: true,
    names: [
      'class zoom link', // Primary
    ],
    description: 'A link to join the Zoom event.',
    examples: ['(Gather will fill)', '(Gather will fill)'],
  },
  {
    prop: 'gatherLink',
    type: 'string',
    allowSpaces: undefined, // Not relevant to write columns
    required: true,
    defaultValue: undefined, // Not relevant to write columns
    isWriteColumn: true,
    names: [
      'class gather link', // Primary
    ],
    description: 'A link to open Gather for this event.',
    examples: ['(Gather will fill)', '(Gather will fill)'],
  },
  {
    prop: 'canvasLink',
    type: 'string',
    allowSpaces: undefined, // Not relevant to write columns
    required: true,
    defaultValue: undefined, // Not relevant to write columns
    isWriteColumn: true,
    names: [
      'canvas course link', // Primary
    ],
    description: 'A link to the Canvas course.',
    examples: ['(Gather will fill)', '(Gather will fill)'],
  },
  {
    prop: 'results',
    type: 'string',
    allowSpaces: undefined, // Not relevant to write columns
    required: true,
    defaultValue: undefined, // Not relevant to write columns
    isWriteColumn: true,
    names: [
      'gather class results', // Primary
    ],
    description: 'Results of the batch creation process.',
    examples: ['(Gather will fill)', '(Gather will fill)'],
  },
];

export default COLUMN_HEADERS;
