/**
 * Admin panel navigator (moves you around between admin panels)
 * @author Gabe Abrams
 */

// Import React
import React, { useEffect, useReducer } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSchool,
  faListAlt,
  faTrashRestore,
  faLink,
  faBan,
} from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  TabBox,
  confirm,
  Variant,
  logClientEvent,
  LogAction,
} from 'dce-reactkit';

// Import shared types
import UserAndCourseInfo from '../../shared/types/UserAndCourseInfo';
import LogMetadata from '../../shared/types/from-server/LogMetadata';

// Import shared components
import Header from '../../shared/Header';

// Import other components
import BatchEventCreator from './BatchEventCreator';
import BreakthroughLinkManager from './BreakthroughLinkManager';
import LoungeBanManager from './LoungeBanManager';
import RecoverEvent from './RecoverEvent';
import JumpToCourse from './JumpToCourse';

// Import other types
import AdminPanelFeatureProps from './types/AdminPanelFeatureProps';

// Style
import './style.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // User and course info
  userAndCourseInfo: UserAndCourseInfo,
  // Function to call when exiting the admin panel
  onExit: () => void,
};

// One feature's info
type Feature = {
  // Id of the feature
  id: string,
  // Icon for the feature
  icon: any,
  // Description of the feature
  description: string,
  // Component for the feature
  component: React.FC<{}> | React.FC<AdminPanelFeatureProps>,
  // If true, the feature is course-specific
  courseSpecific: boolean,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

// List of features
const FEATURES: Feature[] = [
  {
    id: 'AdminPanel-batch-event-creator-button',
    icon: faListAlt,
    description: 'Batch Setup',
    component: BatchEventCreator,
    courseSpecific: false,
  },
  {
    id: 'AdminPanel-breakthrough-link-manager',
    icon: faLink,
    description: 'Breakthrough Manager',
    component: BreakthroughLinkManager,
    courseSpecific: true,
  },
  {
    id: 'AdminPanel-manage-lounge-bans-button',
    icon: faBan,
    description: 'Lounge Bans',
    component: LoungeBanManager,
    courseSpecific: false,
  },
  {
    id: 'AdminPanel-recover-deleted-events-button',
    icon: faTrashRestore,
    description: 'Recover Events',
    component: RecoverEvent,
    courseSpecific: true,
  },
  {
    id: 'AdminPanel-jump-to-another-course-button',
    icon: faSchool,
    description: 'Jump to Course',
    component: JumpToCourse,
    courseSpecific: false,
  },
];

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // Current feature (undefined if on home page)
  currentFeatureId?: string,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Close the current feature and go back to the home page
  GoHome = 'GoHome',
  // Open a specific feature
  OpenFeature = 'OpenFeature',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.OpenFeature,
    // The id of the feature to open
    featureId: string,
  }
  | {
    // Action type
    type: (
      | ActionType.GoHome
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.GoHome: {
      return {
        ...state,
        currentFeatureId: undefined,
      };
    }
    case ActionType.OpenFeature: {
      return {
        ...state,
        currentFeatureId: action.featureId,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AdminPanel: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    userAndCourseInfo,
    onExit,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    currentFeatureId: undefined,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    currentFeatureId,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Try to exit the current feature and go back to the home page
   * @author Gabe Abrams
   */
  const goHome = async () => {
    // Skip if already on home page
    if (!currentFeatureId) {
      return;
    }

    // Ask user to confirm
    const confirmed = await confirm(
      'Abandon Unsaved Changes?',
      'Are you sure you want to return to the admin panel? If you have unsaved changes, they will be permanently lost.',
      {
        confirmButtonText: 'Go to Admin Panel',
        confirmButtonVariant: Variant.Warning,
      },
    );
    if (!confirmed) {
      return;
    }

    // Update state
    dispatch({
      type: ActionType.GoHome,
    });
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      (async () => {
        // Log opening
        logClientEvent({
          context: LogMetadata.Context.AdminPanel,
          action: LogAction.Open,
        });
      })();
    },
    [],
  );

  /**
   * Unmount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      return () => {
        // Log opening
        logClientEvent({
          context: LogMetadata.Context.AdminPanel,
          action: LogAction.Close,
        });
      };
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  /* ------------- Header ------------- */

  let headerLeftButton: {
    onClick: () => void,
    contents: string,
  };
  if (!currentFeatureId) {
    // Exit admin panel button
    headerLeftButton = {
      onClick: onExit,
      contents: 'Exit',
    };
  } else {
    // In a sub panel that has to be exitable
    headerLeftButton = {
      onClick: goHome,
      contents: 'Admin Panel',
    };
  }

  // Render header
  const header = (
    <Header
      leftButton={headerLeftButton}
      showingAdminPanel
      userAndCourseInfo={userAndCourseInfo}
    />
  );

  /* ---------- Feature/Home ---------- */

  // Body
  let body: React.ReactNode;

  // Find the feature
  const currentFeature = (
    currentFeatureId
      ? FEATURES.find((candidateFeature) => {
        return candidateFeature.id === currentFeatureId;
      })
      : undefined
  );
  if (currentFeature) {
    // There's a current feature
    body = (
      <currentFeature.component
        userAndCourseInfo={userAndCourseInfo}
      />
    );
  } else {
    // Home

    /* --------- Feature Buttons -------- */

    const courseSpecificFeatureButtons: React.ReactNode[] = [];
    const globalFeatureButtons: React.ReactNode[] = [];

    FEATURES.forEach((feature) => {
      // Create the button
      const button = (
        <button
          key={feature.id}
          type="button"
          id={feature.id}
          className="btn btn-secondary btn-lg pt-3 pb-3 flex-grow-1"
          style={{
            flexBasis: 1,
          }}
          aria-label={feature.description}
          onClick={() => {
            // Open the feature
            dispatch({
              type: ActionType.OpenFeature,
              featureId: feature.id,
            });
          }}
        >
          <FontAwesomeIcon
            icon={feature.icon}
            style={{
              fontSize: '2rem',
            }}
          />
          <h2
            className="m-0"
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {feature.description}
          </h2>
        </button>
      );

      // Add to the appropriate list
      if (feature.courseSpecific) {
        courseSpecificFeatureButtons.push(button);
      } else {
        globalFeatureButtons.push(button);
      }
    });

    // Assemble body
    body = (
      <div key="admin-panel-home">
        <h1 className="text-white mb-0">
          Admin Panel&nbsp;
          <strong>
            Home
          </strong>
        </h1>
        <p className="text-white lead mb-3">
          This panel is available only to Canvas admins
        </p>

        {/* Global Actions */}
        <div className="mb-4">
          <TabBox title="Global Actions">
            <div className="d-flex align-items-center justify-content-center flex-wrap gap-2">
              {globalFeatureButtons}
            </div>
          </TabBox>
        </div>

        {/* Course-specific Actions */}
        <div className="mb-4">
          <TabBox title="Current Course Actions">
            <div className="d-flex align-items-center justify-content-center flex-wrap gap-2">
              {courseSpecificFeatureButtons}
            </div>
          </TabBox>
        </div>
      </div>
    );
  }

  /* ---------- Put Together ---------- */

  return (
    <div className="AdminPanel-striped-admin-background progress-bar-striped">
      <div className="content-container">
        {/* Header */}
        {header}

        {/* Content below header */}
        <div className="App-content-below-header AdminPanel-body-container text-center">
          {/* Content */}
          {body}
        </div>
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default AdminPanel;
