/**
 * Capitalize the first letter of each word
 * @author Gabe Abrams
 * @param name the name of the header
 * @return capitalized header name
 */
const toHeaderCase = (name: string) => {
  return (
    name
      // Split into words
      .split(' ')
      // Go through each word and capitalize it
      .map((word) => {
        return `${word.charAt(0).toUpperCase()}${word.substring(1) || ''}`;
      })
      // Join words with spaces
      .join(' ')
  );
};

export default toHeaderCase;
