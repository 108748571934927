/**
 * Create Event Badges component for a Prospective Event
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import dce-reactkit
import { Variant } from 'dce-reactkit';

// Import helpers
import ProspectiveEvent from '../helpers/ProspectiveEvent';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // The Prospective event to preview
  event: ProspectiveEvent,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

const BADGE_MAP = {
  LOCK_AUTO_RECORD_SETTING: {
    variant: Variant.Info,
    text: 'Lock Auto Record',
    name: 'lock-auto-record-setting',
  },
  AUTO_RECORD: {
    variant: Variant.Success,
    text: 'Auto Record',
    name: 'auto-record',
  },
  BAN_DCE: {
    variant: Variant.Danger,
    text: 'Ban DCE',
    name: 'ban-dce',
  },
  BAN_FAS: {
    variant: Variant.Danger,
    text: 'Ban FAS',
    name: 'ban-fas',
  },
  WAITING_ROOM: {
    variant: Variant.Primary,
    text: 'Waiting Room',
    name: 'waiting-room',
  },
  EMERGENCY_EVENT: {
    variant: Variant.Warning,
    text: 'Emergency Event',
    name: 'emergency-event',
  },
  ENSURE_LOUNGE_EXISTS: {
    variant: Variant.Secondary,
    text: 'Ensure Lounge',
    name: 'ensure-lounge',
  },
  HOST_VIDEO_DISABLED: {
    variant: Variant.Danger,
    text: 'Host Video Disabled',
    name: 'host-video-disabled',
  },
  IS_WEBINAR: {
    variant: Variant.Warning,
    text: 'Webinar',
    name: 'webinar',
  },
};

/*------------------------------------------------------------------------*/
/* --------------------------- Static Helpers --------------------------- */
/*------------------------------------------------------------------------*/

/**
 * Helper to make a single badge based on badge type
 * @author Gabe Abrams
 * @param badgeType the type of badge to make
 * @param crn the course CRN for element id
 * @returns the badge
 */
const makeBadge = (
  badgeType: {
    variant: string,
    text: string,
    name: string,
  },
  crn: string,
): React.ReactNode => {
  return (
    <span
      key={badgeType.text}
      className={`EventBadges-badge-crn-${crn} EventBadges-badge-type-${badgeType.name} badge badge-pill bg-${badgeType.variant} me-2`}
      id={`EventBadges-badge-crn-${crn}-type-${badgeType.name}`}
    >
      {badgeType.text}
    </span>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const EventBadges: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    event,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Iterate over badge options and add if needed
  const badges: React.ReactNode[] = [];
  const crn = event.getCRN();
  if (event.isAutoRecordOn()) {
    badges.push(makeBadge(BADGE_MAP.AUTO_RECORD, crn));
  }
  if (event.isWaitingRoomOn()) {
    badges.push(makeBadge(BADGE_MAP.WAITING_ROOM, crn));
  }
  if (event.isLockAutoRecordSettingOn()) {
    badges.push(makeBadge(BADGE_MAP.LOCK_AUTO_RECORD_SETTING, crn));
  }
  if (event.isDCEBanOn()) {
    badges.push(makeBadge(BADGE_MAP.BAN_DCE, crn));
  }
  if (event.isFASBanOn()) {
    badges.push(makeBadge(BADGE_MAP.BAN_FAS, crn));
  }
  if (event.isEmergencyEvent()) {
    badges.push(makeBadge(BADGE_MAP.EMERGENCY_EVENT, crn));
  }
  if (event.isEnsuringLoungeExists()) {
    badges.push(makeBadge(BADGE_MAP.ENSURE_LOUNGE_EXISTS, crn));
  }
  if (event.isHostVideoDisabled()) {
    badges.push(makeBadge(BADGE_MAP.HOST_VIDEO_DISABLED, crn));
  }
  if (event.getIsWebinar()) {
    badges.push(makeBadge(BADGE_MAP.IS_WEBINAR, crn));
  }

  return (
    <div className="EventBadges-container">
      {badges}
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default EventBadges;
