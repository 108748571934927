/**
 * Log contexts, tags, and other metadata
 * @author Gabe Abrams
 */
const LogMetadata = {
  // Contexts
  Context: {
    General: 'General',
    Home: {
      _: 'Home',
      AttendanceSubpanel: 'AttendanceSubpanel',
      CreateEditEventSubpanel: 'CreateEditEventSubpanel',
      CreateEditLoungeSubpanel: 'CreateEditLoungeSubpanel',
      HelpSubpanel: 'HelpSubpanel',
      RecordingsSubpanel: 'RecordingsSubpanel',
      ChooseJoinTypeSubpanel: 'ChooseJoinTypeSubpanel',
      LoungePreparationSubpanel: 'LoungePreparationSubpanel',
      ShareableLinkSubpanel: 'ShareableLinkSubpanel',
      ClaimAccountNotice: 'ClaimAccountNotice',
    },
    AdminPanel: {
      _: 'AdminPanel',
      BatchEventCreator: 'BatchEventCreator',
      BreakthroughLinkManager: 'BreakthroughLinkManager',
      LoungeBanManager: 'LoungeBanManager',
      RecoverEvent: 'RecoverEvent',
      JumpToCourse: 'JumpToCourse',
    },
  },
  // Tags
  Tag: {},
  // Targets
  Target: {
    CourseLounge: 'CourseLounge',
    CourseEvent: 'CourseEvent',
    BreakthroughLink: 'BreakthroughLink',
    RecoverableEvent: 'RecoverableEvent',
    SelfEnteredAttendance: 'SelfEnteredAttendance',
    PublishedRecording: 'PublishedRecording',
  },
};

export default LogMetadata;
