// Import shared types
import ProspectiveEventStatus from '../types/ProspectiveEventStatus';

// Import shared helpers
import getBatchCreateSuccessMessage from './getBatchCreateSuccessMessage';

/* ------------------------ ID Management ----------------------- */

// The next id number (must be incremented before used, so first id is "1")
let nextId = 0;

/**
 * Create a new event ID
 * @author Gabe Abrams
 * @returns next unique id for the event
 */
const genID = () => {
  nextId += 1;
  return nextId;
};

/* ---------------------------- Class --------------------------- */

/**
 * A prospective event that might be created
 * @author Gabe Abrams
 */
class ProspectiveEvent {
  // Private instance variables
  private crn: string;
  private courseCode: string;
  private hostEmail: string;
  private isLockAutoRecordSettingOnValue: boolean = false;
  private isWaitingRoomOnValue: boolean = false;
  private isAutoRecordOnValue: boolean = false;
  private isDCEBanOnValue: boolean = false;
  private isFASBanOnValue: boolean = false;
  private applyUpdates: (updates: { [k: string]: any }) => Promise<void>;
  private skipReasons: string[] | undefined;
  private alreadyHasResultsValue: boolean = false;
  private isEmergencyEventValue: boolean = false;
  private ensureLoungeExists: boolean = false;
  private hostVideoDisabled: boolean = false;
  private customEventName: string;
  private isWebinar: boolean = false;
  private id: number;
  private status: ProspectiveEventStatus;
  private updates: { [k: string]: any };
  private errorMessage: string | undefined;
  private warningMessage: string | undefined;

  /**
   * Initialize the ProspectiveEvent
   * @author Gabe Abrams
   * @param opts object containing all arguments
   * @param opts.crn the crn for the course
   * @param opts.courseCode the course code
   * @param opts.hostEmail the host email for the event to create
   * @param opts.applyUpdates an async function that takes an
   *   update map and applies the updates to the sheet. Takes just one argument:
   *   { prop => value } where prop can be any of the following:
   *   openZoomLink, gatherLink, zoomPassword, canvasLink, results
   * @param [opts.isLockAutoRecordSettingOn] if true, the event
   *   auto-recording setting will be editable
   * @param [opts.isWaitingRoomOn] if true, the meeting will have
   *   a waiting room
   * @param [opts.isAutoRecordOn] if true, the meeting will be
   *   automatically recorded to the cloud
   * @param [opts.isDCEBanOn] if true, DCE students will be banned
   *   from seeing or joining this event
   * @param [opts.isFASBanOn] if true, FAS students will be banned
   *   from seeing or joining this event
   * @param [opts.skipReasons] if defined, this event will not be
   *   created and this array of strings is a list of human-readable
   *   explanations for why the event cannot be created
   * @param [opts.alreadyHasResults] if true, this event will be
   *   skipped because it already has text in one of the result columns
   * @param [opts.isEmergencyEvent] if true, this event will
   *   be marked as an emergency event
   * @param [opts.ensureLoungeExists] if true and no study lounge
   *   exists, create a study lounge under the same account as the event
   * @param [opts.hostVideoDisabled] if true, force host video to
   *   be off
   * @param [opts.customEventName] if defined, the custom name
   *   of the event
   * @param [opts.isWebinar] if true, event is a webinar
   */
  constructor(
    opts: {
      crn: string,
      courseCode: string,
      hostEmail: string,
      applyUpdates: (updates: { [k: string]: any }) => Promise<void>,
      isLockAutoRecordSettingOn?: boolean,
      isWaitingRoomOn?: boolean,
      isAutoRecordOn?: boolean,
      isDCEBanOn?: boolean,
      isFASBanOn?: boolean,
      skipReasons?: string[],
      alreadyHasResults?: boolean,
      isEmergencyEvent?: boolean,
      ensureLoungeExists?: boolean,
      hostVideoDisabled?: boolean,
      customEventName?: string,
      isWebinar?: boolean,
    },
  ) {
    // Store params to instance variables
    this.crn = opts.crn;
    this.courseCode = opts.courseCode;
    this.hostEmail = opts.hostEmail;
    this.isLockAutoRecordSettingOnValue = !!opts.isLockAutoRecordSettingOn;
    this.isWaitingRoomOnValue = !!opts.isWaitingRoomOn;
    this.isAutoRecordOnValue = !!opts.isAutoRecordOn;
    this.isDCEBanOnValue = !!opts.isDCEBanOn;
    this.isFASBanOnValue = !!opts.isFASBanOn;
    this.applyUpdates = opts.applyUpdates;
    this.skipReasons = opts.skipReasons;
    this.alreadyHasResultsValue = !!opts.alreadyHasResults;
    this.isEmergencyEventValue = !!opts.isEmergencyEvent;
    this.ensureLoungeExists = !!opts.ensureLoungeExists;
    this.hostVideoDisabled = !!opts.hostVideoDisabled;
    this.customEventName = opts.customEventName ?? 'Class';
    this.isWebinar = !!opts.isWebinar;
    // Initialize status
    this.status = ProspectiveEventStatus.Pending;

    // Get an id for this event
    this.id = genID();

    // Initialize a map of updates
    this.updates = {}; // prop => new value
  }

  /* --------------------------- Getters -------------------------- */

  /**
   * Get the id of the event
   * @author Gabe Abrams
   * @returns the unique id
   */
  getID() {
    return this.id;
  }

  /**
   * Get the current status of the event
   * @author Gabe Abrams
   * @returns event status
   */
  getStatus() {
    return this.status;
  }

  /**
   * Get the CRN for the course that will contain this event
   * @author Gabe Abrams
   * @return  the crn
   */
  getCRN() {
    return this.crn;
  }

  /**
   * Get the course code for the course that will contain this event
   * @author Gabe Abrams
   * @return  the course code
   */
  getCourseCode() {
    return this.courseCode;
  }

  /**
   * Get the email of the zoom host that will own the corresponding zoom meeting
   * @author Gabe Abrams
   * @return  the zoom host email
   */
  getHostEmail() {
    return this.hostEmail;
  }

  /**
   * Check if this event auto-record setting will be editable by non-admins
   * @author Gabe Abrams
   * @return  true if event auto-record setting will be editable
   */
  isLockAutoRecordSettingOn() {
    return this.isLockAutoRecordSettingOnValue;
  }

  /**
   * Check if this event will have a waiting room
   * @author Gabe Abrams
   * @return  true if event will have a waiting room
   */
  isWaitingRoomOn() {
    return this.isWaitingRoomOnValue;
  }

  /**
   * Check if this event will be auto-recorded to the cloud
   * @author Gabe Abrams
   * @return  true if event will be auto-recorded
   */
  isAutoRecordOn() {
    return this.isAutoRecordOnValue;
  }

  /**
   * Check if DCE students are banned from this event
   * @author Gabe Abrams
   * @return  true if banned
   */
  isDCEBanOn() {
    return this.isDCEBanOnValue;
  }

  /**
   * Check if FAS students are banned from this event
   * @author Gabe Abrams
   * @return  true if banned
   */
  isFASBanOn() {
    return this.isFASBanOnValue;
  }

  /**
   * Check if this event is an emergency meeting
   * @author Gabe Abrams
   * @return  true if this is an emergency meeting
   */
  isEmergencyEvent() {
    return this.isEmergencyEventValue;
  }

  /**
   * Check if ensuring that a study lounge exists
   * @author Gabe Abrams
   * @returns true if ensuring a lounge exists
   */
  isEnsuringLoungeExists() {
    return this.ensureLoungeExists;
  }

  /**
   * Check if host zoom is forced off
   * @author Gabe Abrams
   * @returns true if host zoom is off
   */
  isHostVideoDisabled() {
    return this.hostVideoDisabled;
  }

  /**
   * Get custom event name
   * @author Gabe Abrams
   * @returns the custom event name
   */
  getCustomEventName() {
    return this.customEventName;
  }

  /**
   * Check if the event is a webinar
   * @author Gabe Abrams
   * @returns true if the event is a webinar
   */
  getIsWebinar() {
    return this.isWebinar;
  }

  /**
   * Return the reasons why this event was skipped
   * @author Gabe Abrams
   * @returns skip reasons
   */
  listSkipReasons() {
    return this.skipReasons || [];
  }

  /**
   * Check if this event already has results
   * @author Gabe Abrams
   * @returns true if the event is being skipped because it already
   *   has text in one of the result columns
   */
  alreadyHasResults() {
    return this.alreadyHasResultsValue;
  }

  /**
   * Get the error message explaining why the event failed
   * @author Gabe Abrams
   * @returns error message
   */
  getErrorMessage() {
    return this.errorMessage;
  }

  /**
   * Get the warning message
   * @author Gabe Abrams
   * @return {string} warning message
   */
  getWarningMessage() {
    return this.warningMessage;
  }

  /* --------------------------- Setters -------------------------- */

  /**
   * Set the event Zoom link
   * @author Gabe Abrams
   * @param openZoomLink the zoom link for the event
   */
  setOpenZoomLink(openZoomLink: string) {
    if (openZoomLink && openZoomLink.trim().length > 0) {
      this.updates.openZoomLink = openZoomLink.trim();
    }
  }

  /**
   * Set the event Gather link
   * @author Gabe Abrams
   * @param gatherLink the Gather link for the event
   */
  setGatherLink(gatherLink: string) {
    if (gatherLink && gatherLink.trim().length > 0) {
      this.updates.gatherLink = gatherLink.trim();
    }
  }

  /**
   * Set the event Zoom password
   * @author Gabe Abrams
   * @param zoomPassword the zoom meeting password for the event
   */
  setZoomPassword(zoomPassword: string) {
    if (zoomPassword && zoomPassword.trim().length > 0) {
      this.updates.zoomPassword = zoomPassword.trim();
    }
  }

  /**
   * Set the Canvas course link
   * @author Gabe Abrams
   * @param canvasLink the link to the Canvas course site
   */
  setCanvasLink(canvasLink: string) {
    if (canvasLink && canvasLink.trim().length > 0) {
      this.updates.canvasLink = canvasLink.trim();
    }
  }

  /**
   * Set the results of the event creation process
   * @author Gabe Abrams
   * @param status the status of the event creation
   * @param [message] a human-readable message describing the results
   *   (required if status is CREATED_WITH_WARNING)
   * @returns an update transaction
   */
  setResults(status: ProspectiveEventStatus, message?: string) {
    // Change the status
    this.status = status;

    // Save error message
    if (status === ProspectiveEventStatus.Failed) {
      this.errorMessage = message;
    }

    // Save warning message
    if (status === ProspectiveEventStatus.CreatedWithWarning) {
      this.warningMessage = message;
    }

    // Use success message if relevant
    const newMessage = (
      // Check if successful and another message isn't already provided
      (status === ProspectiveEventStatus.Successful && !message)
        ? getBatchCreateSuccessMessage(
          this.ensureLoungeExists,
        ) // Default mess.
        : message // Use provided message
    );

    // Store the update
    this.updates.results = status;
    if (newMessage && newMessage.trim().length > 0) {
      this.updates.results += `: ${newMessage.trim()}`;
    }
  }

  /* ---------------------------- Save ---------------------------- */

  /**
   * Save changes to the data store
   * @author Gabe Abrams
   */
  async save() {
    // Perform updates
    await this.applyUpdates(this.updates);

    // Clear updates
    this.updates = {};
  }
}

export default ProspectiveEvent;
